/* .navigation-menu {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.navigation-menu li {
  margin: 0px 20px;
}
.navigation-menu li a {
  text-decoration: none;
  color: white;
} */


.transparent-bg-dropdown.btn {
  background-color: transparent !important;
  border-color: transparent !important; /* Optionally, make border transparent too */
  color: inherit !important; /* Optionally, inherit text color */
}

.smallscreen {
  padding: 1.5em 7em !important;
  height: 100%;
  background-color: white !important;
}
.service-dropdown {
  border-radius: 0.25rem;
  padding: 55px;
  background: white;
  color: black;
  top: 100px;
  position: fixed;
  width: 100%;
  max-width: 100vw !important;
  z-index: 2;
  height: 72%;
}
/* .css-ag7rrr-MuiTypography-root{
  font-family: 'Ubuntu', sans-serif !important;
  font-size: 20px !important;
  color:aqua !important;
 
} */
.css-ahj2mt-MuiTypography-root {
  font-size: 16px !important;
}
.logo {
  padding: 25px 0;
}
ul li.header-items {
  padding: 15px 10px 15px;
}
.btny.btn-primaryy {
  background-color:#17a2b8 !important;
  color: white;
}
.btn.btn-primary {
  background-color:transparent !important;
  border-color:transparent !important;
  color: white;
  font-size: larger;
}
.button-header {
  text-decoration: none;
  font-size: large;
  margin: 2 0;
  display: block;
  padding: 8px;
  color: inherit;
}
.active {
  color: #17a2b8 !important;
  font-weight: bold !important;
}
/* mobile view */
.mobile-navigation {
  list-style: none;
  background-color: black;
}
.mobile-navigation li {
  margin-top: 20px;
}
.mobile-navigation li a {
  align-items: center;
  display: flex;
  justify-content: left;
  text-decoration: none;
  font-size: 18px;
  color: white;
}
#contact-button {
  background-color: aqua;
  padding: 10px;
  border-radius: 10px;
}
.anchorstyle{
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: normal !important;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    padding: 12px 0; 
    text-decoration: none;
}
.anchorstyle:hover{
  color: black !important
}
#navlink {
  color: black !important;
}
@media  (min-width: 901px) and (max-width: 990px) {
  .smallscreen {
    padding: 1em 2em !important;;
  }
}

@media only screen and (max-width: 900px) {
  .smallscreen {
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    height: 40vh !important;
    padding: 1px 0px !important;
    text-align: left !important;
    background-color: white !important;
  }
  .service-dropdown {
    height: 50vh;
    padding: 1em !important;
    display: none !important;
  }
  .smallservice {
    height: 50vh !important;
    padding: 1em !important;
    background: white !important;
    color: black !important;
    width: 98% !important;
    overflow-y: scroll !important;
  }
  #service-menu-s::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
    padding-right: 20px;
  }

  #service-menu-s::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  #service-menu-s::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
}
.header-items {
  padding: 1em;
  list-style: none;
}
.header-items:hover {
  color: aqua;
}
.drop-down::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 2px;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.drop-down:hover {
  color: aqua;
}
