.team-banner{
    position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
#tabheading{
    font-family: 'Ubuntu', sans-serif;
    font-size: 26px;
    padding-bottom: 12px;
}
.card{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: whitesmoke,
}
.card:hover{
    padding: 10px;
    background-color: #17a2b8 !important
}
#nohoverbody{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
}
#hoverbody{
    color: white;
}
#hovercard #hoverbody{
    display: none;
}
#hovercard:hover #hoverbody{
    display: block;
}
#hovercard:hover #nohoverbody{
    display: none;
}
#mission{
    width: 100%;
}
.background{
    background-color: #17a2b8;
    padding: 40px;
    color: white;
    
}
#image-team{
        background-color: steelblue;
        height: 100vh;
        background-image: url(../images/team.jpg);
        background-size: cover;
        background-position: center;
        background-blend-mode: multiply;
        color: #fff;
   
}
#image-careers{
    background-color: steelblue;
    height: 100vh;
    background-image: url(../images/careers_bg.jpg);
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    color: #fff;

}
#about-image1{
    height: 100vh;
    background-color: steelblue;
    background-image: url(../images/applyjobposition.jpeg);
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    color: #fff;

}
#about-image2{
    height: 100vh;
    background-color: steelblue;
    background-image: url(../images/clientsbg.jpg);
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    color: #fff;

}
#about-image{
    height: 100vh;
    background-color: steelblue;
    background-image: url(../images/wearewho.avif);
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    color: #fff;

}
#contact-image{
    background-color: steelblue;
    background-image: url(../images/contact\ us.png);
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    color: #fff;

}
#contact-image1{
    background-color: steelblue;
    background-image: url(../images/workisfun.jpeg);
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    color: #fff;

}
  @media only screen and (max-width: 900px) {
    #mission{
        display: none;
    }
  }
  .job-card {
    width: 500px;
    height: 200px;
    position: relative;
    margin-top: 20px;
    border: 2px solid transparent; /* Ensure there's a border for the transition effect */
    transition: background-color 0.3s;
  }
  
  .job-card:hover {
    background-color: #e0e0e0; /* Change the color as needed */
  }