/* Default styles for desktop view */
.slider-container {
    width: 60%;
    overflow: hidden;
    height: 10%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .slider {
    position: relative;
    height: 10%;
  }
  
  .slider-wrapper {
    display: flex;
    transition: transform 0.5s ease;
    margin: 0 -10px; /* Adjust the spacing between images */
  }
  
  .slide {
    flex: 0 0 20%; /* Each slide takes up 1/5 of the container on desktop */
    padding: 0 10px; /* Adjust the spacing between images */
    overflow: hidden;
  }
  
  .slider-image {
    width: 100%;
    height: 100%; /* Maintain aspect ratio */
    object-fit:fill; /* Ensure the image covers the entire container */
    transition: transform 0.5s ease;
  }
  
  .center {
    transform: scale(1.2); /* Center slide is larger */
  }
  
  .slider-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-size: 1.5rem;
    padding: 0.5rem;
    cursor: pointer;
    z-index: 1;
  }
  
  .prev {
    left: 0;
  }
  
  .next {
    right: 0;
  }
  
  /* Media query for responsiveness */
  @media (max-width: 767px) {
    .slide {
      flex: 0 0 20%; /* Each slide takes up full width on mobile */
      padding: 0; /* Remove horizontal padding on mobile */
    }
  
    .slider-image {
      width: 100%;
      height: auto; /* Auto height to maintain aspect ratio */
    }

    .slider-container {
    width: 100%;
    overflow: hidden;
    height: 10%;
  }
  }
  