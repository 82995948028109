body {
  overflow-x: hidden;
}
.home {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

#heading{
  background-color: rgb(0, 0,0 , 0.8);  /* border-left: red ; */
  width: auto;
  box-sizing: border-box;
  position: top;
  padding: 0px;
}
#sbuheading{
  background-color: rgb(0, 0, 0, 0.8);  /* border-left: red ; */
  width: auto;
  box-sizing: initial;
  margin-left: 10%;
  margin-right: 10%;
  position: top;
  padding: 0px;
  color: white;
}

@media screen and (max-width: 768px) {
  .main-heading {
    font-size: 10px; /* Adjusted font size for mobile devices */
  }
}

#heading h1 {
  font-weight: bold;
  font-size: 4vw;
  color: white;
  text-align: center;
}
#sbuheading h3 {
  font-weight: bold;
  color: white;
  text-align: center;
  font-size: 1.7em; /* Adjust the base font size as needed */
}

@media screen and (max-width: 768px) {
  #sbuheading h3 {
    font-size: 1.5em; /* Adjust the font size for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  #sbuheading h3 {
    font-size: 0.8em; /* Adjust the font size for even smaller screens */
  }
}

#heading::before {
  content: "";
  border-left: 7px solid #17a2b8;
  border-bottom: 7px solid #17a2b8;
  border-top: 7px solid #17a2b8;
  top: 0px;
  left: -7px;
  height: 100%;
  width: 75px;
  position: absolute;
}
#heading::after {
  content: "";
  border-right: 7px solid #17a2b8;
  border-bottom: 7px solid #17a2b8;
  border-top: 7px solid #17a2b8;
  top: 0px;
  right: -7px;
  height: 100%;
  width: 75px;
  position: absolute;
}
.learn-button {
  background-color: transparent;
  color: white;
  position: absolute;
  top: 70%;
  font-size: 20px;
  font-weight: bold;
  top:70%;
  font-size: 3vw;
  font-weight: bold; 
  display: inline-block;
  text-decoration: none;
}
.learn-button::after {
  content: "";
  border-bottom: 7px solid #17a2b8;
  display: block;
  margin-top: 15px;
}

.learn-button:hover{
  color: #17a2b8;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.background {
  background-image: url(../images/whowearebg.jpg) !important;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 15px;
}
.learnMore {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif !important
}
.learnMore:hover {
  color: #0056b3;
}
.count-number {
  font-size: 35px !important;
  font-weight: bold !important;
}
.css-ahj2mt-MuiTypography-root {
  font-size: 20px !important;
}
.css-1p823my-MuiListItem-root {
  padding-left: 0 !important;
  padding-bottom: 0 !important;
}
.css-10hburv-MuiTypography-root {
  font-size: 14px !important;
  cursor: pointer !important;
}
.location-card{
  display: flex;
  align-items: center;
  justify-content: center;
}
.effect:hover {
  background-image: linear-gradient(#4589ff, #08bdba);
  color: white;
}
#learnbutton {
  color: white;
  border: 2px solid white;
}
#learnbutton:hover {
  color: white;
  border: 2px solid #17a2b8;
}
#headerbutton {
  color: white;
  background-color: #17a2b8;
  margin-left: 3px;
  cursor: pointer;
}
#headerbutton:hover {
  color: white;
  background-color: #17a2b8;
  margin-left: 3px;
}
#footerbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.statspadd {
  padding: 15px 0;
}
#counterContainer-large{
  display: block;
}
#counterContainer-small{
  display: none;
}
/* mobile view  */

@media only screen and (max-width: 768px) {
  .home {
    justify-content: center;
    align-items: center;
  }
  .headerContainer {
    margin-left: 0;
    border-radius: 10px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #121619;
    color: white;
  }
  .home .headerContainer h1 {
    font-size: 40px;
    height: 30px;
    margin: 30px 0px;
    color: white;
  }
  .home .headerContainer p {
    font-size: 30px;
    color: white;
  }
  .headerContainer button {
    background-color: white;
    color: #121619;
    margin-bottom: 30px;
  }
  #footerbox {
    display: block;
    align-items: center;
    justify-content: center;
  }
  #divide {
    border-top: 1px solid white;
  }
  #counterContainer-large{
    display: none;
  }
  #counterContainer-small{
    display: block;
  }
}
.carousel-item {
  font-family: "Ubuntu", sans-serif;
}
.carousel-item p {
  color: #17a2b8 !important ;
}
.carousel-indicators {
  position: absolute;
  z-index: 1 !important;
}
.carousel-indicators button {
  height: 10px !important;
  width: 10px !important;
  border-radius: 50%;
  cursor: pointer;
}
.carousel-indicators [data-bs-target] {
  background-color: #17a2b8 !important;
}

.carousel-control-prev-icon {
  background-image: url(../images/prev.png) !important;
}
.carousel-control-next-icon {
  background-image: url(../images/next.png) !important;
}